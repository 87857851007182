import styled from 'styled-components';
import React, { useCallback } from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { eventSenderGA } from '../../tools';
import { LinkOutlined } from '@ant-design/icons';

const ShareGroupDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 2.5rem;
`;
const URLShareButton = styled.button`
  padding: 0;
  width: 48px;
  height: 48px;
  color: white;
  border-radius: 24px;
  border: 0px;
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
  background-color: black;
`;

const ShareGroup = ({ testTitle }) => {
  const threads_scouter_url = 'https://threadsscouter.com/';
  const share_title = 'Threads Scouter - Threads Insight Dashboard';
  const _onShareButtonClick = useCallback(() => {
    eventSenderGA('Sharing', 'Click Copy-link Button', 'main page');
    alert('Link Copied!');
  }, []);

  const _onFacebookButtonClick = useCallback(() => {
    eventSenderGA('Sharing', 'Click Facebook-share Button', 'main page');
  }, []);

  const _onTwitterButtonClick = useCallback(() => {
    eventSenderGA('Sharing', 'Click Twitter-share Button', 'main page');
  }, []);

  return (
    <ShareGroupDiv>
      <FacebookShareButton
        url={threads_scouter_url}
        quote={share_title}
        hashtag={'#threads'}
      >
        <FacebookIcon
          onClick={_onFacebookButtonClick}
          size={40}
          round={true}
          style={{ padding: '0 0.5rem' }}
          bgStyle={{ fill: 'none' }}
        />
      </FacebookShareButton>
      <TwitterShareButton url={threads_scouter_url} title={share_title}>
        <XIcon
          onClick={_onTwitterButtonClick}
          size={37}
          round={true}
          style={{ padding: '0 0.5rem' }}
        />
      </TwitterShareButton>
      <CopyToClipboard text={threads_scouter_url}>
        <URLShareButton onClick={_onShareButtonClick}>
          <LinkOutlined />
        </URLShareButton>
      </CopyToClipboard>
    </ShareGroupDiv>
  );
};

export default ShareGroup;
