import React from 'react';
import styles from './athread.module.css';
import ThreadInsightTable from './MyProfile/ThreadsListStyle/ThreadInsightTable';

const AThread = ({ thread }) => {
  // const media_renderer = (threads) => {
  //   if (
  //     threads?.media_type === 'IMAGE' ||
  //     threads?.media_type === 'CAROUSEL_ALBUM'
  //   ) {
  //     return <img src={threads?.media_url} alt={threads?.id} width={'70%'} />;
  //   } else if (threads?.media_type === 'VIDEO') {
  //     return (
  //       <video width={'70%'} controls autoplay>
  //         <source src={threads?.media_url} />
  //       </video>
  //     );
  //   }
  // };
  return (
    <div className={styles.mainDiv}>
      <div className={styles.mainTextDiv}>
        <p className={styles.mainText}>{thread?.text}</p>
      </div>
      {/* {media_renderer(thread)} */}
      <p>---</p>
      {thread?.views ? <ThreadInsightTable thread={thread} /> : null}
      <p>
        {thread?.timestamp?.split('T')[0]}{' '}
        {thread?.timestamp?.split('T')[1].split('+')[0] || null}
      </p>
    </div>
  );
};

export default AThread;
