import {
  GlobalOutlined,
  NumberOutlined,
  PushpinOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import { Col, Row, Segmented } from 'antd';
import React, { Fragment, useState } from 'react';
import styles from '../profileViewer.module.css';

const FollowersDemographic = ({ demoMetric }) => {
  const [currentDemoCategory, setCurrentDemoCategory] = useState('age');
  return (
    <div className={styles.followersDemoDiv}>
      <h3 className={styles.demoTitle}>My Followers' Demographic</h3>
      {demoMetric?.length > 0 ? (
        <>
          <Segmented
            options={[
              {
                label: (
                  <div className={styles.eachSegment}>
                    <NumberOutlined />
                    <div>age</div>
                  </div>
                ),
                value: 'age',
              },
              {
                label: (
                  <div className={styles.eachSegment}>
                    <WomanOutlined />
                    <div>gender</div>
                  </div>
                ),
                value: 'gender',
              },
              {
                label: (
                  <div className={styles.eachSegment}>
                    <GlobalOutlined />
                    <div>country</div>
                  </div>
                ),
                value: 'country',
              },
              {
                label: (
                  <div className={styles.eachSegment}>
                    <PushpinOutlined />
                    <div>city</div>
                  </div>
                ),
                value: 'city',
              },
            ]}
            onChange={(value) => {
              setCurrentDemoCategory(value);
            }}
          />
          {demoMetric &&
            demoMetric
              .filter(
                (elem) =>
                  elem?.total_value?.breakdowns?.[0]?.dimension_keys[0] ===
                  currentDemoCategory
              )
              ?.map((demo_info) => (
                <Row className={styles.followersDemoRow} key={demo_info?.id}>
                  {demo_info?.total_value?.breakdowns?.[0]?.results
                    ?.sort((a, b) => b.value - a.value)
                    .map((result) => (
                      <Fragment key={result?.dimension_values[0]}>
                        <Col span={8}>
                          <h4>
                            {demo_info?.total_value?.breakdowns?.[0]
                              ?.dimension_keys[0] === 'city'
                              ? result?.dimension_values[0]?.split(',')?.[0]
                              : result?.dimension_values[0]}
                          </h4>
                          <h5>{result?.value?.toLocaleString()}</h5>
                        </Col>
                      </Fragment>
                    ))}
                </Row>
              ))}
        </>
      ) : (
        <p>Available for over 100 followers.</p>
      )}
    </div>
  );
};

export default FollowersDemographic;
