import React, { useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { eventSenderGA } from '../tools';

const CheckBeforeUseButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    eventSenderGA('Popup', 'Click Popup Button', 'main page');
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button danger ghost className='check-privacy-button' onClick={showModal}>
        !! Check before USE !!
      </Button>
      <Modal
        onOk={handleOk}
        onCancel={handleCancel}
        open={isModalOpen}
        width={'80%'}
        style={{ maxWidth: '416px' }}
        title={<h4 style={{ margin: '0 auto 1rem' }}>ℹ DON'T WORRY</h4>}
        footer={[
          <Button type='primary' key={'okay'} onClick={handleOk}>
            Okay
          </Button>,
        ]}
      >
        <h3>🔐 개인정보는 걱정마세요!</h3>
        <p>
          Meta의 심사를 받고 Threads 공식 API를 사용해 배포했습니다.
          <br />
          <br />
          로그인한 유저의 정보를{' '}
          <strong style={{ color: '#1677FF', fontSize: '1rem' }}>
            절대!
          </strong>{' '}
          저장하지 않습니다.
          <br />
          (데이터를 저장하는 서버 자체가 없어요 😭)
        </p>
        <Divider />
        <h3>📱 새로운 기기 로그인 확인!</h3>
        <p>
          로그인 후 인스타그램에서 보낸
          <br />
          <strong style={{ color: '#1677FF', fontSize: '1rem' }}>
            새로운 기기 인증 메일
          </strong>
          을 꼭 확인해주세요.
          <br />
          <br />
          본인 인증이 안될 경우 스레드에서 추후 댓글 기능을 제한할 수 있습니다.
        </p>
      </Modal>
    </div>
  );
};

export default CheckBeforeUseButton;
