import React, { useEffect, useMemo, useState } from 'react';
import { Divider } from 'antd';
import styles from './profileViewer.module.css';
import './datepicker.css';
import BasicProfile from './MyProfile/BasicProfile';
import BasicInsights from './MyProfile/BasicInsights';
import InsightsGraph from './MyProfile/InsightsGraph';
import FollowersDemographic from './MyProfile/FollowersDemographic';
import MyThreadsList from './MyProfile/MyThreadsList';
import ApplyWaitlist from './MyProfile/ApplyWaitlist';

const ProfileViewer = ({
  user,
  metric,
  myThreadsList,
  myThreadsListPaging,
  isLoading,
  onClickReDoButton,
  onClickLoadMoreButton,
}) => {
  const [normalMetric, setNorMalMetric] = useState([]);
  const [demoMetric, setDemoMetric] = useState([]);
  // eslint-disable-next-line
  const sampleUser = {
    username: 'be_seeyong',
    threads_profile_picture_url:
      'https://scontent-ssn1-1.cdninstagram.com/v/t51.2885-15/448582644_324697557242557_6649477878082184346_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=C3qRfi0XaiMQ7kNvgGztlvT&_nc_ht=scontent-ssn1-1.cdninstagram.com&edm=AP4hL3IEAAAA&oh=00_AYADuT3xNtgB2CGkBfOqAqLUFAnTnzQ9GurlzxCXix7-IQ&oe=667B1C00',
    threads_biography:
      '인터뷰어&편집장 | 프로그래머 | CFA Charterholder\n🎙️ 프로들의 전문성을 인터뷰하고 <더퍼슨스>\n⌨️ 수익형 웹사이트를 코딩하고 <케이테스트>\n☝️ 프로그래밍과 금융을 강의하고 <코딩배워투자하자>',
    id: '7634085360032451',
    user_profile_url: 'https://www.threads.net/@be_seeyong',
  };
  // eslint-disable-next-line
  const sampleMetric = useMemo(
    () => [
      {
        name: 'views',
        period: 'day',
        values: [
          {
            value: 110110,
            end_time: '2024-06-21T07:00:00+0000',
          },
          {
            value: 160110,
            end_time: '2024-06-22T07:00:00+0000',
          },
          {
            value: 141080,
            end_time: '2024-06-23T07:00:00+0000',
          },
          {
            value: 110180,
            end_time: '2024-06-24T07:00:00+0000',
          },
          {
            value: 119280,
            end_time: '2024-06-25T07:00:00+0000',
          },
          {
            value: 191080,
            end_time: '2024-06-26T07:00:00+0000',
          },
          {
            value: 110580,
            end_time: '2024-06-27T07:00:00+0000',
          },
          {
            value: 150110,
            end_time: '2024-06-28T07:00:00+0000',
          },
          {
            value: 170110,
            end_time: '2024-06-29T07:00:00+0000',
          },
          {
            value: 131080,
            end_time: '2024-06-30T07:00:00+0000',
          },
          {
            value: 210180,
            end_time: '2024-07-01T07:00:00+0000',
          },
          {
            value: 110280,
            end_time: '2024-07-02T07:00:00+0000',
          },
          {
            value: 170080,
            end_time: '2024-07-03T07:00:00+0000',
          },
          {
            value: 210080,
            end_time: '2024-07-04T07:00:00+0000',
          },
        ],
        title: '조회',
        description: '프로필이 조회된 횟수.',
        id: '17841400155892134/insights/views/day',
        value: 110,
      },
      {
        name: 'likes',
        period: 'day',
        title: '좋아요',
        description: '게시물의 좋아요 수.',
        total_value: {
          value: 0,
        },
        id: '17841400155892134/insights/likes/day',
        value: 10000,
      },
      {
        name: 'replies',
        period: 'day',
        title: '답글',
        description: '게시물의 답글 수.',
        total_value: {
          value: 0,
        },
        id: '17841400155892134/insights/replies/day',
        value: 10000,
      },
      {
        name: 'quotes',
        period: 'day',
        title: '인용',
        description: '게시물이 인용된 횟수.',
        total_value: {
          value: 0,
        },
        id: '17841400155892134/insights/quotes/day',
        value: 10000,
      },
      {
        name: 'reposts',
        period: 'day',
        title: '리포스트',
        description: '게시물이 리포스트된 횟수.',
        total_value: {
          value: 0,
        },
        id: '17841400155892134/insights/reposts/day',
        value: 10000,
      },
      {
        name: 'followers_count',
        period: 'day',
        title: '팔로워 수',
        description: 'Threads에 있는 총 팔로워 수.',
        total_value: {
          value: 192,
        },
        id: '17841400155892134/insights/followers_count/day',
        value: 19210000,
      },
      {
        name: 'follower_demographics',
        period: 'day',
        title: 'follower_demographics',
        description: '팔로워의 국가, 도시, 성별 분포 등 인구 통계학적 특성.',
        total_value: {
          breakdowns: [
            {
              dimension_keys: ['country'],
              results: [
                {
                  dimension_values: ['DE'],
                  value: 1,
                },
                {
                  dimension_values: ['AE'],
                  value: 1,
                },
                {
                  dimension_values: ['JP'],
                  value: 1,
                },
                {
                  dimension_values: ['KR'],
                  value: 166,
                },
                {
                  dimension_values: ['IT'],
                  value: 1,
                },
                {
                  dimension_values: ['ID'],
                  value: 1,
                },
                {
                  dimension_values: ['CN'],
                  value: 1,
                },
                {
                  dimension_values: ['GB'],
                  value: 1,
                },
                {
                  dimension_values: ['MY'],
                  value: 1,
                },
                {
                  dimension_values: ['KH'],
                  value: 1,
                },
                {
                  dimension_values: ['CA'],
                  value: 1,
                },
                {
                  dimension_values: ['US'],
                  value: 4,
                },
              ],
            },
          ],
        },
        id: '17841400155892134/insights/follower_demographics/day',
      },
      {
        name: 'follower_demographics',
        period: 'day',
        title: 'follower_demographics',
        description: '팔로워의 국가, 도시, 성별 분포 등 인구 통계학적 특성.',
        total_value: {
          breakdowns: [
            {
              dimension_keys: ['city'],
              results: [
                {
                  dimension_values: ['London, England'],
                  value: 1,
                },
                {
                  dimension_values: ['Gangneung, Gangwon-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Gwangju, Gwangju'],
                  value: 2,
                },
                {
                  dimension_values: ['Calgary, Alberta'],
                  value: 1,
                },
                {
                  dimension_values: ['Daegu, Daegu'],
                  value: 2,
                },
                {
                  dimension_values: ['Yongin, Gyeonggi-do'],
                  value: 3,
                },
                {
                  dimension_values: ['Berlin, Berlin'],
                  value: 1,
                },
                {
                  dimension_values: ['Suncheon, Jeollanam-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Busan, Busan'],
                  value: 7,
                },
                {
                  dimension_values: ['Dangjin, Chungcheongnam-do'],
                  value: 2,
                },
                {
                  dimension_values: ['Seoul, Seoul'],
                  value: 104,
                },
                {
                  dimension_values: ['Yangju, Gyeonggi-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Goyang, Gyeonggi-do'],
                  value: 2,
                },
                {
                  dimension_values: ['Honolulu, Hawaii'],
                  value: 1,
                },
                {
                  dimension_values: ['River Edge, New Jersey'],
                  value: 1,
                },
                {
                  dimension_values: ['Phnom Penh, Phnom Penh'],
                  value: 1,
                },
                {
                  dimension_values: ['Jakarta, Jakarta'],
                  value: 1,
                },
                {
                  dimension_values: ['Namyangju, Gyeonggi-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Gimpo, Gyeonggi-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Shanghai, Shanghai'],
                  value: 1,
                },
                {
                  dimension_values: ['Toyonaka-shi, Osaka Prefecture'],
                  value: 1,
                },
                {
                  dimension_values: ['Sejong, Chungcheongbuk-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Incheon, Incheon'],
                  value: 3,
                },
                {
                  dimension_values: ['Hanam, Gyeonggi-do'],
                  value: 4,
                },
                {
                  dimension_values: ['Kuala Lumpur, Kuala Lumpur'],
                  value: 1,
                },
                {
                  dimension_values: ['Jeonju, Jeollabuk-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Hwaseong, Gyeonggi-do'],
                  value: 5,
                },
                {
                  dimension_values: ['Seogwipo, Jeju-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Daejeon, Daejeon'],
                  value: 2,
                },
                {
                  dimension_values: ['Ansan, Gyeonggi-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Anyang, Gyeonggi-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Suwon, Gyeonggi-do'],
                  value: 2,
                },
                {
                  dimension_values: ['Bucheon, Gyeonggi-do'],
                  value: 3,
                },
                {
                  dimension_values: ['Taebaek, Gangwon-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Rome, Lazio'],
                  value: 1,
                },
                {
                  dimension_values: ['Gunsan, Jeollabuk-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Fremont, California'],
                  value: 1,
                },
                {
                  dimension_values: ['Seongnam, Gyeonggi-do'],
                  value: 3,
                },
                {
                  dimension_values: ['New York, New York'],
                  value: 1,
                },
                {
                  dimension_values: ['Dubai, Dubai'],
                  value: 1,
                },
                {
                  dimension_values: ['Gunpo, Gyeonggi-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Siheung, Gyeonggi-do'],
                  value: 3,
                },
                {
                  dimension_values: ['Chungju, Chungcheongbuk-do'],
                  value: 1,
                },
                {
                  dimension_values: ['Paju, Gyeonggi-do'],
                  value: 3,
                },
                {
                  dimension_values: ['Andong, Gyeongsangbuk-do'],
                  value: 1,
                },
              ],
            },
          ],
        },
        id: '17841400155892134/insights/follower_demographics/day',
      },
      {
        name: 'follower_demographics',
        period: 'day',
        title: 'follower_demographics',
        description: '팔로워의 국가, 도시, 성별 분포 등 인구 통계학적 특성.',
        total_value: {
          breakdowns: [
            {
              dimension_keys: ['age'],
              results: [
                {
                  dimension_values: ['18-24'],
                  value: 2,
                },
                {
                  dimension_values: ['25-34'],
                  value: 52,
                },
                {
                  dimension_values: ['35-44'],
                  value: 67,
                },
                {
                  dimension_values: ['45-54'],
                  value: 38,
                },
                {
                  dimension_values: ['55-64'],
                  value: 18,
                },
                {
                  dimension_values: ['65+'],
                  value: 3,
                },
              ],
            },
          ],
        },
        id: '17841400155892134/insights/follower_demographics/day',
      },
      {
        name: 'follower_demographics',
        period: 'day',
        title: 'follower_demographics',
        description: '팔로워의 국가, 도시, 성별 분포 등 인구 통계학적 특성.',
        total_value: {
          breakdowns: [
            {
              dimension_keys: ['gender'],
              results: [
                {
                  dimension_values: ['F'],
                  value: 50,
                },
                {
                  dimension_values: ['M'],
                  value: 114,
                },
                {
                  dimension_values: ['U'],
                  value: 16,
                },
              ],
            },
          ],
        },
        id: '17841400155892134/insights/follower_demographics/day',
      },
    ],
    []
  );
  // eslint-disable-next-line
  const sampleMyThreads = useMemo(
    () => [
      {
        timestamp: '2024-07-02T09:37:50+0000',
        text: '📈 프로필 조회수 - "개발 중인" 지표\n\n궁금해서 API 문서를 살펴보니 Profile Views 관련된 기능은 "개발 중인 지표"라고 하네요.\n(즉, Meta도 아직 정확한 통계치를 추산하는 중이라는 의미)\n\n개인적인 추측(절대 Meta의 공식 견해 ❌)으로는,프로필을 조회하는 기준을 여러 가지로 잡을 수 있는데요.\n\n한 계정이 해당 페이지를 여러 번 들락날락해도 다 카운트하기 or N초 내에 한 계정이 해당 페이지를 여러 번 조회하면 조회수 1로 카운트하기 or 어뷰징처럼 조회하면 들락날락하는 계정의 조회수 Stop 시키기 etc.\n\n프로필 조회수가 상상했던 것 이상으로 높다면 한 계정이 여러 번 들락날락해도 다 카운트해주는 것 아닐까 생각해요(절대 Meta의 공식 견해 ❌).\n\n그래서 우선 Meta의 "개발 완료" 공지가 나오기 전까지는 대략의 추이만 참고하시면 될 듯해요!\n\nhttps://www.facebook.com/business/help/metrics-labeling',
        media_type: 'IMAGE',
        media_url:
          'https://scontent-ssn1-1.cdninstagram.com/v/t51.29350-15/449689882_486621757250266_5631143440814713608_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=18de74&_nc_ohc=Mc5WMuGKH1EQ7kNvgF0dFlS&_nc_ht=scontent-ssn1-1.cdninstagram.com&edm=ACx9VUEEAAAA&oh=00_AYDgPqv5mjemJm6evfa7dkJfWFL0owYVpIENlbeqzTU5eQ&oe=668A70DF',
        permalink: 'https://www.threads.net/@be_seeyong/post/C86mFL6PDSR',
        shortcode: 'C86mFL6PDSR',
        is_quote_post: false,
        id: '18002720624624668',
        likes: 30000000,
        quotes: 30000000,
        replies: 30000000,
        reposts: 30000000,
        views: 300000000,
      },
      {
        timestamp: '2024-07-02T08:23:26+0000',
        text: '🐞 날짜 선택 버그 수정 (ver. 0.1.3)\nhttps://threadsscouter.com/\n\n내 스레드 기본 인사이트 날짜 선택 시 몇몇 계정에서 하루밖에 선택되지 않는 이슈를 수정했습니다.\n\n❓버그의 원인\nAPI에서 기본적으로 읽어오는 스레드 리스트의 개수가 한정되어 있는데, 해당 계정이 올린 스레드 양이 많을 경우 리미트가 걸리기 때문이었습니다.\n\n❗️수정한 사항\n영상에서와 같이 해당 계정이 조회할 수 있는 범위를 벗어나게 되면 알림창을 통해 알려주도록 했어요.\n\n✨ 알림창에 나오는 일자는 Threads API에서 직접 알려주는 날짜를 그대로 보여주는 일자예요. 그 일자 범위를 참고해 조정해서 조회하시면 돼요.\n\n* 2024년 4월 13일 이전 자료는 API에서 아예 조회할 수 없도록 해두었어요.\n\n---\n🔥 몇몇 계정으로 로그인이 되지 않는 버그는 수정 중에 있습니다 🛠️',
        media_type: 'VIDEO',
        media_url:
          'https://scontent-ssn1-1.cdninstagram.com/o1/v/t16/f2/m69/An_Ll23oaBncQhHHUPhq1rLR8gmR4ql3s7Ef5vzNR_dCKTxb6zDoCI7gmuZqWjVG8zL47AUYI_lOV4lQnnzm?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmZlZWQudW5rbm93bi1DMy43MjAuZGFzaF9iYXNlbGluZV8xX3YxIn0&_nc_ht=scontent-ssn1-1.cdninstagram.com&_nc_cat=104&vs=1004178988075301_3459422363&_nc_vs=HBksFQIYOnBhc3N0aHJvdWdoX2V2ZXJzdG9yZS9HQ0Nzd2hyNHJhblRLM2NCQUk1STZuSnhhcjBEYmtZTEFBQUYVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dBMjJ5aG9xNFBubWxNd0lBTEhPSG10VzF0dFBia1lMQUFBRhUCAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJty%2F3om35cA%2FFQIoAkMzLBdAM0zMzMzMzRgSZGFzaF9iYXNlbGluZV8xX3YxEQB16gcA&ccb=9-4&oh=00_AYBqfSHwPlq9zBnmtPwGcao1jBhBM0WTcVzx7hu6kpJc5g&oe=668688D3&_nc_sid=1d576d',
        permalink: 'https://www.threads.net/@be_seeyong/post/C86dihiP2VU',
        shortcode: 'C86dihiP2VU',
        is_quote_post: false,
        id: '18030666659481532',
        likes: 2,
        quotes: 0,
        replies: 0,
        reposts: 0,
        views: 30,
      },
      {
        timestamp: '2024-07-02T06:04:49+0000',
        text: "월 $167K를 웹 사이트로만 벌고 있는 세계 정상급 인디해커 Pieter Levels\n\n가 7월 1일 새로운 사이트를 출시했습니다.\n\nhttps://luggagelosers.com/\n\n✈️ 세계 항공사 중 승객 짐을 가장 많이 잃어버리는 회사 순위 사이트\n\n🇮🇳 Air India\n🇮🇪 Aer Lingus\n🇬🇧 British Airways\n순으로 이어지는데요.\n대한민국 항공사는 순위에 없네요 👍\n\n💰 서비스 관점에서 눈에 띄는 점\nPieter Levels의 또 다른 블록버스터 사이트인 NomadList.com 처럼 '랭킹 사이트'가 아무래도 많은 이들의 흥미를 손쉽게 끌 수 있는 프레임이죠.\n\n🛠️ 개발 관점에서 눈에 띄는 점\n[Last updated: 6 hours ago from 1,477 live sources]\n즉, 1,477개 실시간 소스에서 데이터를 가져온다고 하는데 어떤 데이터 스트림 구조를 갖고 있는지, 유료/무료 중 어떤 파이프라인을 사용하고 있는지 등이 궁금하네요.",
        media_type: 'TEXT_POST',
        permalink: 'https://www.threads.net/@be_seeyong/post/C86NtBnys4i',
        shortcode: 'C86NtBnys4i',
        is_quote_post: false,
        id: '18003765476557024',
        likes: 2,
        quotes: 0,
        replies: 0,
        reposts: 0,
        views: 30,
      },
      {
        timestamp: '2024-07-01T12:11:12+0000',
        text: "📈 내 프로필 view 그래프 추가 (ver. 0.1.2)\n\n내 스레드 계정 프로필 조회수 추이를 날짜별로 한눈에 볼 수 있는 '그래프 섹션'을 추가했어요.\n\n✨ 영상과 같이 기본 범위 이외에도 캘린더에서 원하는 기간을 선택하고 버튼을 누르면 조회가 돼요.\n\n---\n* 2024년 6월 1일 이전 자료는 API에서 아예 조회할 수 없도록 해두었어요.\n\n* 그리고 당연하게도 6월 1일 이후라고 하더라도 가입 날짜 이전 데이터는 조회할 수 없어요(첫 스레드 발행일을 최소 날짜로 설정해 두었습니다).\n\n🔥 앞으로도 UI/UX 디자인과 새로운 기능들은 계속 추가해 나갈 거예요. 사용해 보시고 많은 피드백 부탁드려요!",
        media_type: 'VIDEO',
        media_url:
          'https://scontent-ssn1-1.cdninstagram.com/o1/v/t16/f2/m69/An8Hgw950gkJ3dB1xdraLv21XA1Qh0JN64yGAWU-9N2HiFyWHbZ-_2PN1-pFtpOlvrN6ASJPag9aIr-tfZpa2H5p?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmZlZWQudW5rbm93bi1DMy40ODAuZGFzaF9iYXNlbGluZV8xX3YxIn0&_nc_ht=scontent-ssn1-1.cdninstagram.com&_nc_cat=105&vs=1654806848696032_3006488701&_nc_vs=HBkcFQIYOnBhc3N0aHJvdWdoX2V2ZXJzdG9yZS9HSVZhdnhvRW01endtc1lDQUF2aHowT3VZTTlhYmtZTEFBQUYVAALIAQAoABgAGwGIB3VzZV9vaWwBMRUAACao27%2F13YzxQBUCKAJDMywXQDgu2RaHKwIYEmRhc2hfYmFzZWxpbmVfMV92MREAdeoHAA%3D%3D&ccb=9-4&oh=00_AYCLk19gb1a3TFkB3UMcEoWsrmiBUr_8uEVWupfrDYuTxA&oe=668673B9&_nc_sid=1d576d',
        permalink: 'https://www.threads.net/@be_seeyong/post/C84SzjzvRt7',
        shortcode: 'C84SzjzvRt7',
        is_quote_post: false,
        id: '18009607865287627',
        likes: 2,
        quotes: 0,
        replies: 0,
        reposts: 0,
        views: 30,
      },
      {
        timestamp: '2024-07-01T03:57:21+0000',
        text: "'너 같으면 하겠냐?'라고 묻는 사람.\n\n새 비즈니스나 서비스를 시작할 때 스스로 던지는 질문이다.\n'네가 유저라면 이 사이트에 들어와서 시작 버튼을 눌러보겠냐?' 아니, 그전에 '이 사이트 링크를 클릭이나 해보겠냐?라고.\n\n우선 시장에 내놓아야 반응을 알 수 있다는 의견이 100% 맞지만, 가설(hypothesis)을 세우고 나서 검증해야 한다는 말도 100% 맞다. 그게 비즈니스 세계에서 높은 확률로 먹히는 룰이다.\n\n2020년 케이테스트를 만들 때도 'MBTI 관련 검색량은 증가하는데 테스트 공급량이 부족하다'라는 전제를 세우며 시작했고, 스레드 스카우터를 시작할 때도 '스레드 앱에서 확인할 수 없는 지표를 빠르게 독점 공급하면 무조건 트래픽이 생길 것이다'라는 가설에서 시작했다. 이외의 서비스들도 마찬가지.\n\n그리고 '너 같으면 하겠냐?'라고 스스로에게 질문들 던졌을 때 모두 'Okay'라는 답을 내렸다.",
        media_type: 'TEXT_POST',
        permalink: 'https://www.threads.net/@be_seeyong/post/C83aUiwS6kC',
        shortcode: 'C83aUiwS6kC',
        is_quote_post: false,
        id: '17960921645650986',
        likes: 2,
        quotes: 0,
        replies: 0,
        reposts: 0,
        views: 30,
      },
    ],
    []
  );
  // eslint-disable-next-line
  const sampleMyThreadsPaging = useMemo(
    () => [
      {
        cursors: {
          before:
            'QVFIUnhHdGdmMjZAQQWN6S3BIWmQ4M3dxRmNJZATJBOWQybFFZAV09ES2ZAXcHBReTBDWDBza3dfd1ptREh5QTZABUlREQk01ZAWRQcDBQY19HUGFSaDV4YjBpbzN3',
          after:
            'QVFIUjhaZADJsZAGtGbTl3a3hObGJQMm56N0ExbVlFQjdUQzRtUEJLZAFBoVnBuRlppZADdmd3V4Q2EzckNpX3pkbXRjcnRPd1ExbS1tWWs2X0dmTks3TkRBZAWJ3',
        },
        next: 'https://graph.threads.net/v1.0/17841463480427789/threads?fields=timestamp%2Ctext%2Cmedia_type%2Cmedia_url%2Cpermalink%2Cshortcode%2Cis_quote_post&limit=5&access_token=THQWJWUy12ODJaeFZA1U0VreDktY1JnSnU3bU82cnpkb1piVURzZAWxaTmpiLWhueDhEeFRnakhPU1JUdE1uVVR5WnJnb2k0cmphUlE5WWljaExzYXZA4MnV5MzFRRW5pQ25mNVdva1l4dHhUOXRwTTl3eW1nMUoxbldKNGViVGQ1MVVHZAU5Wd3JWSVVmZATYtOVRXWmcZD&pretty=1&after=QVFIUjhaZADJsZAGtGbTl3a3hObGJQMm56N0ExbVlFQjdUQzRtUEJLZAFBoVnBuRlppZADdmd3V4Q2EzckNpX3pkbXRjcnRPd1ExbS1tWWs2X0dmTks3TkRBZAWJ3',
      },
    ],
    []
  );
  // eslint-disable-next-line
  const sampleThreadsInsight = useMemo(
    () => [
      {
        data: {
          data: [
            {
              name: 'views',
              period: 'lifetime',
              values: [
                {
                  value: 23,
                },
              ],
              title: '조회',
              description:
                '게시물이 조회된 횟수. 이 지표는 개발 중입니다(https://www.facebook.com/business/help/metrics-labeling)',
              id: '18038602105802936/insights/views/lifetime',
            },
            {
              name: 'likes',
              period: 'lifetime',
              values: [
                {
                  value: 2,
                },
              ],
              title: '좋아요',
              description: '게시물의 좋아요 수.',
              id: '18038602105802936/insights/likes/lifetime',
            },
            {
              name: 'replies',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '답글',
              description: '게시물의 답글 수.',
              id: '18038602105802936/insights/thread_replies/lifetime',
            },
            {
              name: 'reposts',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '리포스트',
              description: '게시물이 리포스트된 횟수.',
              id: '18038602105802936/insights/reposts/lifetime',
            },
            {
              name: 'quotes',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '인용',
              description: '게시물이 인용된 횟수.',
              id: '18038602105802936/insights/quotes/lifetime',
            },
          ],
        },
        status: 200,
        statusText: '',
        headers: {
          'cache-control': 'private, no-cache, no-store, must-revalidate',
          'content-length': '437',
          'content-type': 'application/json; charset=UTF-8',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          pragma: 'no-cache',
        },
        config: {
          transitional: {
            silentJSONParsing: true,
            forcedJSONParsing: true,
            clarifyTimeoutError: false,
          },
          adapter: ['xhr', 'http', 'fetch'],
          transformRequest: [null],
          transformResponse: [null],
          timeout: 0,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          maxContentLength: -1,
          maxBodyLength: -1,
          env: {},
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
          method: 'get',
          url: 'https://graph.threads.net/18038602105802936/insights?metric=views%2Clikes%2Creplies%2Creposts%2Cquotes&access_token=THQWJXM3RBM3d0Tzg0VkxVVWU4Y21GeDhLMG04cjFnVzJsYzB6Y2c3WEF2cVJZAVk9oOE50b2pTMWpqdEt6cDdoOC1yR1p3ZAzdSOUhJZAnl3V3RKM0o2MFdXN3NLUHZAweEtOd1VMQVJ4ckN0YnR3WGp3dVNqOHVFV3hma3lUaHNMUUtMdlV5ZAjVnLUFfa1BFZAUpZAR3cZD',
        },
        request: {},
      },
      {
        data: {
          data: [
            {
              name: 'views',
              period: 'lifetime',
              values: [
                {
                  value: 85,
                },
              ],
              title: '조회',
              description:
                '게시물이 조회된 횟수. 이 지표는 개발 중입니다(https://www.facebook.com/business/help/metrics-labeling)',
              id: '18023464673204807/insights/views/lifetime',
            },
            {
              name: 'likes',
              period: 'lifetime',
              values: [
                {
                  value: 4,
                },
              ],
              title: '좋아요',
              description: '게시물의 좋아요 수.',
              id: '18023464673204807/insights/likes/lifetime',
            },
            {
              name: 'replies',
              period: 'lifetime',
              values: [
                {
                  value: 1,
                },
              ],
              title: '답글',
              description: '게시물의 답글 수.',
              id: '18023464673204807/insights/thread_replies/lifetime',
            },
            {
              name: 'reposts',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '리포스트',
              description: '게시물이 리포스트된 횟수.',
              id: '18023464673204807/insights/reposts/lifetime',
            },
            {
              name: 'quotes',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '인용',
              description: '게시물이 인용된 횟수.',
              id: '18023464673204807/insights/quotes/lifetime',
            },
          ],
        },
        status: 200,
        statusText: '',
        headers: {
          'cache-control': 'private, no-cache, no-store, must-revalidate',
          'content-length': '442',
          'content-type': 'application/json; charset=UTF-8',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          pragma: 'no-cache',
        },
        config: {
          transitional: {
            silentJSONParsing: true,
            forcedJSONParsing: true,
            clarifyTimeoutError: false,
          },
          adapter: ['xhr', 'http', 'fetch'],
          transformRequest: [null],
          transformResponse: [null],
          timeout: 0,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          maxContentLength: -1,
          maxBodyLength: -1,
          env: {},
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
          method: 'get',
          url: 'https://graph.threads.net/18023464673204807/insights?metric=views%2Clikes%2Creplies%2Creposts%2Cquotes&access_token=THQWJXM3RBM3d0Tzg0VkxVVWU4Y21GeDhLMG04cjFnVzJsYzB6Y2c3WEF2cVJZAVk9oOE50b2pTMWpqdEt6cDdoOC1yR1p3ZAzdSOUhJZAnl3V3RKM0o2MFdXN3NLUHZAweEtOd1VMQVJ4ckN0YnR3WGp3dVNqOHVFV3hma3lUaHNMUUtMdlV5ZAjVnLUFfa1BFZAUpZAR3cZD',
        },
        request: {},
      },
      {
        data: {
          data: [
            {
              name: 'views',
              period: 'lifetime',
              values: [
                {
                  value: 194,
                },
              ],
              title: '조회',
              description:
                '게시물이 조회된 횟수. 이 지표는 개발 중입니다(https://www.facebook.com/business/help/metrics-labeling)',
              id: '18063656488523906/insights/views/lifetime',
            },
            {
              name: 'likes',
              period: 'lifetime',
              values: [
                {
                  value: 3,
                },
              ],
              title: '좋아요',
              description: '게시물의 좋아요 수.',
              id: '18063656488523906/insights/likes/lifetime',
            },
            {
              name: 'replies',
              period: 'lifetime',
              values: [
                {
                  value: 2,
                },
              ],
              title: '답글',
              description: '게시물의 답글 수.',
              id: '18063656488523906/insights/thread_replies/lifetime',
            },
            {
              name: 'reposts',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '리포스트',
              description: '게시물이 리포스트된 횟수.',
              id: '18063656488523906/insights/reposts/lifetime',
            },
            {
              name: 'quotes',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '인용',
              description: '게시물이 인용된 횟수.',
              id: '18063656488523906/insights/quotes/lifetime',
            },
          ],
        },
        status: 200,
        statusText: '',
        headers: {
          'cache-control': 'private, no-cache, no-store, must-revalidate',
          'content-length': '443',
          'content-type': 'application/json; charset=UTF-8',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          pragma: 'no-cache',
        },
        config: {
          transitional: {
            silentJSONParsing: true,
            forcedJSONParsing: true,
            clarifyTimeoutError: false,
          },
          adapter: ['xhr', 'http', 'fetch'],
          transformRequest: [null],
          transformResponse: [null],
          timeout: 0,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          maxContentLength: -1,
          maxBodyLength: -1,
          env: {},
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
          method: 'get',
          url: 'https://graph.threads.net/18063656488523906/insights?metric=views%2Clikes%2Creplies%2Creposts%2Cquotes&access_token=THQWJXM3RBM3d0Tzg0VkxVVWU4Y21GeDhLMG04cjFnVzJsYzB6Y2c3WEF2cVJZAVk9oOE50b2pTMWpqdEt6cDdoOC1yR1p3ZAzdSOUhJZAnl3V3RKM0o2MFdXN3NLUHZAweEtOd1VMQVJ4ckN0YnR3WGp3dVNqOHVFV3hma3lUaHNMUUtMdlV5ZAjVnLUFfa1BFZAUpZAR3cZD',
        },
        request: {},
      },
      {
        data: {
          data: [
            {
              name: 'views',
              period: 'lifetime',
              values: [
                {
                  value: 363,
                },
              ],
              title: '조회',
              description:
                '게시물이 조회된 횟수. 이 지표는 개발 중입니다(https://www.facebook.com/business/help/metrics-labeling)',
              id: '18037106470831532/insights/views/lifetime',
            },
            {
              name: 'likes',
              period: 'lifetime',
              values: [
                {
                  value: 3,
                },
              ],
              title: '좋아요',
              description: '게시물의 좋아요 수.',
              id: '18037106470831532/insights/likes/lifetime',
            },
            {
              name: 'replies',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '답글',
              description: '게시물의 답글 수.',
              id: '18037106470831532/insights/thread_replies/lifetime',
            },
            {
              name: 'reposts',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '리포스트',
              description: '게시물이 리포스트된 횟수.',
              id: '18037106470831532/insights/reposts/lifetime',
            },
            {
              name: 'quotes',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '인용',
              description: '게시물이 인용된 횟수.',
              id: '18037106470831532/insights/quotes/lifetime',
            },
          ],
        },
        status: 200,
        statusText: '',
        headers: {
          'cache-control': 'private, no-cache, no-store, must-revalidate',
          'content-length': '439',
          'content-type': 'application/json; charset=UTF-8',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          pragma: 'no-cache',
        },
        config: {
          transitional: {
            silentJSONParsing: true,
            forcedJSONParsing: true,
            clarifyTimeoutError: false,
          },
          adapter: ['xhr', 'http', 'fetch'],
          transformRequest: [null],
          transformResponse: [null],
          timeout: 0,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          maxContentLength: -1,
          maxBodyLength: -1,
          env: {},
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
          method: 'get',
          url: 'https://graph.threads.net/18037106470831532/insights?metric=views%2Clikes%2Creplies%2Creposts%2Cquotes&access_token=THQWJXM3RBM3d0Tzg0VkxVVWU4Y21GeDhLMG04cjFnVzJsYzB6Y2c3WEF2cVJZAVk9oOE50b2pTMWpqdEt6cDdoOC1yR1p3ZAzdSOUhJZAnl3V3RKM0o2MFdXN3NLUHZAweEtOd1VMQVJ4ckN0YnR3WGp3dVNqOHVFV3hma3lUaHNMUUtMdlV5ZAjVnLUFfa1BFZAUpZAR3cZD',
        },
        request: {},
      },
      {
        data: {
          data: [
            {
              name: 'views',
              period: 'lifetime',
              values: [
                {
                  value: 101,
                },
              ],
              title: '조회',
              description:
                '게시물이 조회된 횟수. 이 지표는 개발 중입니다(https://www.facebook.com/business/help/metrics-labeling)',
              id: '17925796148919274/insights/views/lifetime',
            },
            {
              name: 'likes',
              period: 'lifetime',
              values: [
                {
                  value: 5,
                },
              ],
              title: '좋아요',
              description: '게시물의 좋아요 수.',
              id: '17925796148919274/insights/likes/lifetime',
            },
            {
              name: 'replies',
              period: 'lifetime',
              values: [
                {
                  value: 3,
                },
              ],
              title: '답글',
              description: '게시물의 답글 수.',
              id: '17925796148919274/insights/thread_replies/lifetime',
            },
            {
              name: 'reposts',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '리포스트',
              description: '게시물이 리포스트된 횟수.',
              id: '17925796148919274/insights/reposts/lifetime',
            },
            {
              name: 'quotes',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '인용',
              description: '게시물이 인용된 횟수.',
              id: '17925796148919274/insights/quotes/lifetime',
            },
          ],
        },
        status: 200,
        statusText: '',
        headers: {
          'cache-control': 'private, no-cache, no-store, must-revalidate',
          'content-length': '443',
          'content-type': 'application/json; charset=UTF-8',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          pragma: 'no-cache',
        },
        config: {
          transitional: {
            silentJSONParsing: true,
            forcedJSONParsing: true,
            clarifyTimeoutError: false,
          },
          adapter: ['xhr', 'http', 'fetch'],
          transformRequest: [null],
          transformResponse: [null],
          timeout: 0,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          maxContentLength: -1,
          maxBodyLength: -1,
          env: {},
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
          method: 'get',
          url: 'https://graph.threads.net/17925796148919274/insights?metric=views%2Clikes%2Creplies%2Creposts%2Cquotes&access_token=THQWJXM3RBM3d0Tzg0VkxVVWU4Y21GeDhLMG04cjFnVzJsYzB6Y2c3WEF2cVJZAVk9oOE50b2pTMWpqdEt6cDdoOC1yR1p3ZAzdSOUhJZAnl3V3RKM0o2MFdXN3NLUHZAweEtOd1VMQVJ4ckN0YnR3WGp3dVNqOHVFV3hma3lUaHNMUUtMdlV5ZAjVnLUFfa1BFZAUpZAR3cZD',
        },
        request: {},
      },
      {
        data: {
          data: [
            {
              name: 'views',
              period: 'lifetime',
              values: [
                {
                  value: 288,
                },
              ],
              title: '조회',
              description:
                '게시물이 조회된 횟수. 이 지표는 개발 중입니다(https://www.facebook.com/business/help/metrics-labeling)',
              id: '18103888627417264/insights/views/lifetime',
            },
            {
              name: 'likes',
              period: 'lifetime',
              values: [
                {
                  value: 5,
                },
              ],
              title: '좋아요',
              description: '게시물의 좋아요 수.',
              id: '18103888627417264/insights/likes/lifetime',
            },
            {
              name: 'replies',
              period: 'lifetime',
              values: [
                {
                  value: 6,
                },
              ],
              title: '답글',
              description: '게시물의 답글 수.',
              id: '18103888627417264/insights/thread_replies/lifetime',
            },
            {
              name: 'reposts',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '리포스트',
              description: '게시물이 리포스트된 횟수.',
              id: '18103888627417264/insights/reposts/lifetime',
            },
            {
              name: 'quotes',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '인용',
              description: '게시물이 인용된 횟수.',
              id: '18103888627417264/insights/quotes/lifetime',
            },
          ],
        },
        status: 200,
        statusText: '',
        headers: {
          'cache-control': 'private, no-cache, no-store, must-revalidate',
          'content-length': '443',
          'content-type': 'application/json; charset=UTF-8',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          pragma: 'no-cache',
        },
        config: {
          transitional: {
            silentJSONParsing: true,
            forcedJSONParsing: true,
            clarifyTimeoutError: false,
          },
          adapter: ['xhr', 'http', 'fetch'],
          transformRequest: [null],
          transformResponse: [null],
          timeout: 0,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          maxContentLength: -1,
          maxBodyLength: -1,
          env: {},
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
          method: 'get',
          url: 'https://graph.threads.net/18103888627417264/insights?metric=views%2Clikes%2Creplies%2Creposts%2Cquotes&access_token=THQWJXM3RBM3d0Tzg0VkxVVWU4Y21GeDhLMG04cjFnVzJsYzB6Y2c3WEF2cVJZAVk9oOE50b2pTMWpqdEt6cDdoOC1yR1p3ZAzdSOUhJZAnl3V3RKM0o2MFdXN3NLUHZAweEtOd1VMQVJ4ckN0YnR3WGp3dVNqOHVFV3hma3lUaHNMUUtMdlV5ZAjVnLUFfa1BFZAUpZAR3cZD',
        },
        request: {},
      },
      {
        data: {
          data: [
            {
              name: 'views',
              period: 'lifetime',
              values: [
                {
                  value: 160,
                },
              ],
              title: '조회',
              description:
                '게시물이 조회된 횟수. 이 지표는 개발 중입니다(https://www.facebook.com/business/help/metrics-labeling)',
              id: '18044751502872632/insights/views/lifetime',
            },
            {
              name: 'likes',
              period: 'lifetime',
              values: [
                {
                  value: 4,
                },
              ],
              title: '좋아요',
              description: '게시물의 좋아요 수.',
              id: '18044751502872632/insights/likes/lifetime',
            },
            {
              name: 'replies',
              period: 'lifetime',
              values: [
                {
                  value: 4,
                },
              ],
              title: '답글',
              description: '게시물의 답글 수.',
              id: '18044751502872632/insights/thread_replies/lifetime',
            },
            {
              name: 'reposts',
              period: 'lifetime',
              values: [
                {
                  value: 1,
                },
              ],
              title: '리포스트',
              description: '게시물이 리포스트된 횟수.',
              id: '18044751502872632/insights/reposts/lifetime',
            },
            {
              name: 'quotes',
              period: 'lifetime',
              values: [
                {
                  value: 0,
                },
              ],
              title: '인용',
              description: '게시물이 인용된 횟수.',
              id: '18044751502872632/insights/quotes/lifetime',
            },
          ],
        },
        status: 200,
        statusText: '',
        headers: {
          'cache-control': 'private, no-cache, no-store, must-revalidate',
          'content-length': '442',
          'content-type': 'application/json; charset=UTF-8',
          expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          pragma: 'no-cache',
        },
        config: {
          transitional: {
            silentJSONParsing: true,
            forcedJSONParsing: true,
            clarifyTimeoutError: false,
          },
          adapter: ['xhr', 'http', 'fetch'],
          transformRequest: [null],
          transformResponse: [null],
          timeout: 0,
          xsrfCookieName: 'XSRF-TOKEN',
          xsrfHeaderName: 'X-XSRF-TOKEN',
          maxContentLength: -1,
          maxBodyLength: -1,
          env: {},
          headers: {
            Accept: 'application/json, text/plain, */*',
          },
          method: 'get',
          url: 'https://graph.threads.net/18044751502872632/insights?metric=views%2Clikes%2Creplies%2Creposts%2Cquotes&access_token=THQWJXM3RBM3d0Tzg0VkxVVWU4Y21GeDhLMG04cjFnVzJsYzB6Y2c3WEF2cVJZAVk9oOE50b2pTMWpqdEt6cDdoOC1yR1p3ZAzdSOUhJZAnl3V3RKM0o2MFdXN3NLUHZAweEtOd1VMQVJ4ckN0YnR3WGp3dVNqOHVFV3hma3lUaHNMUUtMdlV5ZAjVnLUFfa1BFZAUpZAR3cZD',
        },
        request: {},
      },
    ],
    []
  );

  useEffect(() => {
    const normal_metric = metric?.filter(
      (elem) => elem.name !== 'follower_demographics'
    );
    setNorMalMetric(normal_metric);

    const demo_metric = metric?.filter(
      (demo_elem) => demo_elem?.name === 'follower_demographics'
    );
    setDemoMetric(demo_metric);
  }, [metric, setNorMalMetric, setDemoMetric]);

  return (
    <div>
      <BasicProfile user={user} />
      <Divider className={styles.sectionDivider} />
      <BasicInsights
        normalMetric={normalMetric}
        setNorMalMetric={setNorMalMetric}
        onClickReDoButton={onClickReDoButton}
        isLoading={isLoading}
      />
      <Divider className={styles.sectionDivider} />
      <ApplyWaitlist user={user} metric={normalMetric} />
      <Divider className={styles.sectionDivider} />
      <InsightsGraph normalMetric={normalMetric} />
      <Divider className={styles.sectionDivider} />
      <FollowersDemographic demoMetric={demoMetric} />
      <Divider className={styles.sectionDivider} />
      <MyThreadsList
        myThreadsList={myThreadsList}
        myThreadsListPaging={myThreadsListPaging}
        onClickLoadMoreButton={onClickLoadMoreButton}
      />
    </div>
  );
};

export default ProfileViewer;
