import React, { useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';
import { buildGraphAPIURL } from '../tools';
import { Helmet } from 'react-helmet-async';

const Callback = () => {
  const mainMetaTagRenderer = () => {
    const _metaTag = (
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Threads Scouter for Insights</title>
        <meta name='title' content='Threads Scouter for Insights' />
        <meta
          name='description'
          content='Dashboard for insights of my Threads'
          data-react-helmet='true'
        />
        <meta property='fb:app_id' content='7201589926609452' />
        <link rel='main-url' href={`https://threadsscouter.com/Callback/`} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content='https://threadsscouter.com/Callback/'
        />
        <meta property='og:title' content='Threads Scouter for Insights' />
        <meta
          property='og:description'
          content='Dashboard for insights of my Threads'
        />
        <meta
          property='og:image'
          content='https://dkw7v79uvv4pm.cloudfront.net/threadsscouter/main-thumb.png'
        />
        <meta
          property='og:image:alt'
          content='Dashboard for insights of my Threads'
        />

        {/* <!-- Twitter --> */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content='https://threadsscouter.com/Callback/'
        />
        <meta property='twitter:title' content='Threads Scouter for Insights' />
        <meta
          property='twitter:description'
          content='Dashboard for insights of my Threads'
        />
        <meta
          property='twitter:image'
          content='https://dkw7v79uvv4pm.cloudfront.net/threadsscouter/main-thumb.png'
        />
        <meta
          property='twitter:image:alt'
          content='Dashboard for insights of my Threads'
        />
      </Helmet>
    );
    return _metaTag;
  };
  const navigator = useNavigate();
  const APP_ID = process.env.REACT_APP_THREADS_APP_ID;
  const API_SECRET = process.env.REACT_APP_THREADS_API_SECRET;
  const GRAPH_API_BASE_URL = process.env.REACT_APP_THREADS_GRAPH_API_BASE_URL;
  const REDIRECT_URI = process.env.REACT_APP_THREADS_REDIRECT_URL;

  // eslint-disable-next-line
  const [accessTokenCookies, setAccessTokenCookie] = useCookies([
    'access_token',
  ]);
  // eslint-disable-next-line
  const [userIdCookies, setUserIdCookie] = useCookies(['user_id']);

  useEffect(() => {
    async function main() {
      const params = new URL(document.URL).searchParams;
      const code = params.get('code');

      const uri = buildGraphAPIURL(
        'oauth/access_token',
        {},
        null,
        GRAPH_API_BASE_URL
      );

      try {
        await axios
          .post(
            uri,
            new URLSearchParams({
              client_id: APP_ID,
              client_secret: API_SECRET,
              grant_type: 'authorization_code',
              redirect_uri: REDIRECT_URI,
              code,
            }).toString(),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          )
          .then((res) => {
            const cookieAges = 60 * 60;
            setAccessTokenCookie('access_token', res.data.access_token, {
              path: '/',
              maxAge: cookieAges,
              secure: true,
            });
            setUserIdCookie('user_id', res.data.user_id, {
              path: '/',
              maxAge: cookieAges,
              secure: true,
            });
          })
          .then(() => navigator(`/myThreads`));
      } catch (err) {
        console.error(err?.response?.data);
      }
    }
    main();
  }, [
    navigator,
    APP_ID,
    API_SECRET,
    GRAPH_API_BASE_URL,
    REDIRECT_URI,
    setAccessTokenCookie,
    setUserIdCookie,
  ]);

  return <div>{mainMetaTagRenderer()}</div>;
};

export default Callback;
