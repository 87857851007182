import { buildGraphAPIURL, eventSenderGA } from '../tools';
import { Button } from 'antd';
import React from 'react';

const LoginButton = () => {
  const APP_ID = '1161313808443311';
  const REDIRECT_URI = 'https://threadsscouter.com/Callback';

  const AUTHORIZATION_BASE_URL = 'https://www.threads.net';
  const PARAMS__CLIENT_ID = 'client_id';
  const PARAMS__REDIRECT_URI = 'redirect_uri';
  const PARAMS__RESPONSE_TYPE = 'response_type';
  const PARAMS__SCOPE = 'scope';
  const SCOPES = [
    'threads_basic',
    // 'threads_content_publish',
    'threads_manage_insights',
    // 'threads_manage_replies',
    // 'threads_read_replies',
  ];

  async function onSubmitHandler(e) {
    e.preventDefault();
    eventSenderGA('LogIn', 'Click LogIn Button', 'main page');
    const url = buildGraphAPIURL(
      'oauth/authorize',
      {
        [PARAMS__SCOPE]: SCOPES.join(','),
        [PARAMS__CLIENT_ID]: APP_ID,
        [PARAMS__REDIRECT_URI]: REDIRECT_URI,
        [PARAMS__RESPONSE_TYPE]: 'code',
      },
      null,
      AUTHORIZATION_BASE_URL
    );
    window.location.href = url;
  }

  return (
    <Button type='dashed' onClick={onSubmitHandler} className='login-button'>
      LogIn with Threads
    </Button>
  );
};

export default LoginButton;
