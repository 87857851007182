import {
  EyeOutlined,
  HeartOutlined,
  MessageOutlined,
  ReloadOutlined,
  SoundOutlined,
} from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import styles from './threadsListStyle.module.css';

const ThreadInsightTable = ({ thread }) => {
  const spaces = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
  return (
    <Row className={styles.insightTableRow}>
      <Col span={24} className={styles.insightTableCol}>
        <span>
          <Tooltip title='Views' color='gold' placement='top'>
            <EyeOutlined />{' '}
            {thread?.views ? thread?.views?.toLocaleString() : 0}
          </Tooltip>
        </span>
        {spaces}
        <span>
          <Tooltip title='Likes' color='gold' placement='top'>
            <HeartOutlined />{' '}
            {thread?.likes ? thread?.likes?.toLocaleString() : 0}
          </Tooltip>
        </span>
      </Col>
      <Col span={24} className={styles.insightTableCol}>
        <span>
          <Tooltip title='Replies' color='gold' placement='top'>
            <MessageOutlined />{' '}
            {thread?.replies ? thread?.replies?.toLocaleString() : 0}
          </Tooltip>
        </span>
        {spaces}
        <span>
          <Tooltip title='Reposts' color='gold' placement='top'>
            <ReloadOutlined />{' '}
            {thread?.reposts ? thread?.reposts?.toLocaleString() : 0}
          </Tooltip>
        </span>
      </Col>
      <Col span={24} className={styles.insightTableCol}>
        <span>
          <Tooltip title='Quotes' color='gold' placement='top'>
            <SoundOutlined />{' '}
            {thread?.quotes ? thread?.quotes?.toLocaleString() : 0}
          </Tooltip>
        </span>
      </Col>
    </Row>
  );
};

export default ThreadInsightTable;
