import { Col, Divider, Row } from 'antd';
import React, { Fragment } from 'react';
import styles from './threadsListStyle.module.css';
import ThreadModal from './ThreadModal';
import {
  EyeOutlined,
  HeartOutlined,
  MessageOutlined,
  ReloadOutlined,
  SoundOutlined,
} from '@ant-design/icons';

const GridStyleList = ({
  myThreadsList,
  handleOk,
  handleCancel,
  isModalOpen,
  currentThread,
  showModal,
}) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return (
    <Row>
      {myThreadsList?.map((thread) => (
        <Fragment key={`${thread?.id}-grid-div`}>
          <Col
            key={`${thread?.id}-grid-col`}
            span={8}
            className={styles.myThreadsListCol}
            onClick={showModal(thread)}
          >
            <Row>
              <Col className={styles.insightPreviewCol}>
                <Row
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.insightPreviewRow}
                >
                  <span className={styles.insightPreviewSpan}>
                    <EyeOutlined />{' '}
                    {thread?.views ? formatter.format(thread?.views) : 0}
                  </span>
                  <span className={styles.insightPreviewSpan}>
                    <HeartOutlined />{' '}
                    {thread?.likes ? formatter.format(thread?.likes) : 0}
                  </span>
                </Row>
              </Col>
              <Divider className={styles.insightDivider} />
            </Row>
            {thread?.text?.substring(0, 35)}...
            <Col className={styles.insightPreviewCol}>
              <Divider className={styles.insightDivider} />
              <Row className={styles.insightPreviewRow}>
                <span className={styles.insightPreviewSpan}>
                  <MessageOutlined />{' '}
                  {thread?.replies ? formatter.format(thread?.replies) : 0}
                </span>
                <span className={styles.insightPreviewSpan}>
                  <ReloadOutlined />{' '}
                  {thread?.reposts ? formatter.format(thread?.reposts) : 0}
                </span>
                <span className={styles.insightPreviewSpan}>
                  <SoundOutlined />{' '}
                  {thread?.quotes ? formatter.format(thread?.quotes) : 0}
                </span>
              </Row>
            </Col>
          </Col>
          <ThreadModal
            key={`${thread?.id}-grid-modal`}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            handleOk={handleOk}
            currentThread={currentThread}
            myThreadsList={myThreadsList}
          />
        </Fragment>
      ))}
    </Row>
  );
};

export default GridStyleList;
