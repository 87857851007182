import React, { useEffect, useState } from 'react';
import styles from '../profileViewer.module.css';
import { LoadingOutlined, CalendarOutlined } from '@ant-design/icons';
import { Area } from '@ant-design/charts';

const InsightsGraph = ({ normalMetric }) => {
  const [graphConfig, setGraphConfig] = useState();
  useEffect(() => {
    setGraphConfig({
      data:
        normalMetric?.filter((elem) => elem.name === 'views')?.[0]?.values ||
        [],
      width: window.screen.width * 0.86,
      height: window.screen.height * 0.34,
      padding: 20,
      xField: (d) => new Date(d?.end_time),
      yField: 'value',
      axis: {
        y: { labelFormatter: '~s' },
      },
      style: {
        fill: 'linear-gradient(-90deg, white 0%, gold 100%)',
        fillOpacity: 0.6,
      },
      theme: 'classicDark',
    });
  }, [normalMetric, setGraphConfig]);
  return (
    <div className={styles.userInsightsGraphDiv}>
      {normalMetric ? (
        <div>
          <div style={{ margin: '1rem auto' }}>
            <h3 className={styles.demoTitle}>My Profile Views</h3>
            <p className={styles.demoTitle}>
              {normalMetric
                ?.filter((elem) => elem.name === 'views')?.[0]
                ?.values?.reduce((n, { value }) => n + value, 0)
                ?.toLocaleString()}
            </p>
            <p>
              ( <CalendarOutlined /> same date range with above )
            </p>
            <Area {...graphConfig} />
          </div>
        </div>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  );
};

export default InsightsGraph;
