import { Route, Routes } from 'react-router-dom';
import './App.css';
import ReactGA from 'react-ga4';
import Mainpage from './pages/Mainpage';
import MyThreads from './pages/MyThreads';
import Callback from './pages/Callback';
import Page404 from './pages/Page404';
import Privacy from './pages/Privacy';
import TandC from './pages/TandC';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: 'G-YL1N4B6GB8',
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      },
    ]);
  }, []);
  return (
    <Routes>
      <Route path='/' element={<Mainpage />} />
      <Route path='/Callback' element={<Callback />} />
      <Route path='/myThreads' element={<MyThreads />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/terms' element={<TandC />} />
      <Route path='/*' element={<Page404 />} />
    </Routes>
  );
}

export default App;
