import { ConfigProvider, Divider, List } from 'antd';
import React from 'react';
import styles from './threadsListStyle.module.css';
import ThreadModal from './ThreadModal';
import {
  EyeOutlined,
  HeartOutlined,
  MessageOutlined,
  ReloadOutlined,
  SoundOutlined,
} from '@ant-design/icons';

const ListStyleList = ({
  myThreadsList,
  handleOk,
  handleCancel,
  isModalOpen,
  currentThread,
  showModal,
}) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: '#FFFFFF',
          colorTextDescription: 'white',
        },
        components: {
          Modal: {
            contentBg: 'black',
            titleColor: 'white',
            headerBg: 'black',
            color: 'white',
          },
        },
      }}
    >
      <div style={{ padding: '0 1rem' }}>
        <List
          itemLayout='horizontal'
          dataSource={myThreadsList}
          renderItem={(item) => (
            <>
              <List.Item
                onClick={showModal(item)}
                className={styles.listStyleItem}
              >
                <List.Item.Meta
                  title={`${item?.text?.substring(0, 22)}...`}
                  description={
                    <>
                      <span className={styles.insightPreviewSpan}>
                        <EyeOutlined />{' '}
                        {item?.views ? formatter.format(item?.views) : 0}
                      </span>
                      <span className={styles.insightPreviewSpan}>
                        <HeartOutlined />{' '}
                        {item?.likes ? formatter.format(item?.likes) : 0}
                      </span>
                      <span className={styles.insightPreviewSpan}>
                        <MessageOutlined />{' '}
                        {item?.replies ? formatter.format(item?.replies) : 0}
                      </span>
                      <span className={styles.insightPreviewSpan}>
                        <ReloadOutlined />{' '}
                        {item?.reposts ? formatter.format(item?.reposts) : 0}
                      </span>
                      <span className={styles.insightPreviewSpan}>
                        <SoundOutlined />{' '}
                        {item?.quotes ? formatter.format(item?.quotes) : 0}
                      </span>
                    </>
                  }
                  style={{ color: 'white' }}
                />

                <div>{item?.timestamp?.split('T')[0]}</div>
              </List.Item>
              <Divider className={styles.sectionDivider} />
              <ThreadModal
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                handleOk={handleOk}
                currentThread={currentThread}
                myThreadsList={myThreadsList}
              />
            </>
          )}
        />
      </div>
    </ConfigProvider>
  );
};

export default ListStyleList;
