import React from 'react';
import { useNavigate } from 'react-router';
import { HomeOutlined } from '@ant-design/icons';
import styles from './page404.module.css';
import { Button } from 'antd';

const Page404 = () => {
  const navigation = useNavigate();
  const onGoToIntroButtonClick = () => {
    navigation(`/`);
  };
  return (
    <div className={styles.page404Div}>
      <h3>잘못된 경로 또는 없는 페이지로 들어오셨습니다.</h3>
      <h3>Wrong Path of Page.</h3>
      <Button onClick={onGoToIntroButtonClick} type='dashed'>
        <HomeOutlined />
        &nbsp; Go Back to Home
      </Button>
    </div>
  );
};

export default Page404;
