import React from 'react';
import LoginButton from '../components/LoginButton';
import styles from './mainpage.module.css';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import CheckBeforeUseButton from '../components/CheckBeforeUseButton';
import ShareGroup from '../components/Sub/ShareButtonGroup';

const Mainpage = () => {
  const mainMetaTagRenderer = () => {
    const _metaTag = (
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>Threads Scouter for Insights</title>
        <meta name='title' content='Threads Scouter for Insights' />
        <meta
          name='description'
          content='Dashboard for insights of my Threads'
          data-react-helmet='true'
        />
        <meta property='fb:app_id' content='7201589926609452' />
        <link rel='main-url' href={`https://threadsscouter.com/`} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://threadsscouter.com/' />
        <meta property='og:title' content='Threads Scouter for Insights' />
        <meta
          property='og:description'
          content='Dashboard for insights of my Threads'
        />
        <meta
          property='og:image'
          content='https://dkw7v79uvv4pm.cloudfront.net/threadsscouter/main-thumb.png'
        />
        <meta
          property='og:image:alt'
          content='Dashboard for insights of my Threads'
        />

        {/* <!-- Twitter --> */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://threadsscouter.com/' />
        <meta property='twitter:title' content='Threads Scouter for Insights' />
        <meta
          property='twitter:description'
          content='Dashboard for insights of my Threads'
        />
        <meta
          property='twitter:image'
          content='https://dkw7v79uvv4pm.cloudfront.net/threadsscouter/main-thumb.png'
        />
        <meta
          property='twitter:image:alt'
          content='Dashboard for insights of my Threads'
        />
      </Helmet>
    );
    return _metaTag;
  };
  return (
    <div className='App'>
      {mainMetaTagRenderer()}
      <h1 className={styles.appTitle}>Threads Insights Scouter</h1>
      <ShareGroup />
      <CheckBeforeUseButton />
      <h2
        style={{
          margin: '1rem auto 0',
          color: '#FF4D4E',
          fontFamily: 'Press Start 2P',
        }}
      >
        ^^^
      </h2>
      <LoginButton />
      <div className={styles.developersDiv}>
        <a
          href='https://www.threads.net/@be_seeyong'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.threadsLink}
        >
          <img
            src='https://seeklogo.com/images/T/threads-logo-1ABBA246BE-seeklogo.com.png'
            alt='threads-logo'
            width={20}
            height={20}
            className={styles.threadsLogo}
          />
          <p className={styles.threadsName}>developer's_Threads</p>
        </a>
      </div>
      <div>
        <a
          href='https://www.producthunt.com/posts/threads-scouter?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-threads&#0045;scouter'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=465806&theme=light'
            alt='Threads&#0032;Scouter - Threads&#0032;Insight&#0032;Dashboard | Product Hunt'
            style={{ marginTop: '5rem', width: '250px', height: '54px' }}
            width='250'
            height='54'
          />
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Mainpage;
