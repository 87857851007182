import ReactGA4 from 'react-ga4';

const GRAPH_API_BASE_URL = 'https://graph.threads.net';
const PARAMS__ACCESS_TOKEN = 'access_token';
export function buildGraphAPIURL(path, searchParams, accessToken, base_url) {
  const url = new URL(path, base_url ?? GRAPH_API_BASE_URL);

  url.search = new URLSearchParams(searchParams);
  if (accessToken) {
    url.searchParams.append(PARAMS__ACCESS_TOKEN, accessToken);
  }

  return url.toString();
}

export function getInsightsValue(metrics, index) {
  if (metrics[index]) {
    metrics[index].value = metrics[index].values?.[0]?.value;
  }
}

export function getInsightsTotalValue(metrics, index) {
  if (metrics[index]) {
    metrics[index].value = metrics[index].total_value?.value;
  }
}

export function getInsightsDemoValue(metrics, index) {
  if (metrics[index]) {
    metrics[index].breakdowns = metrics[index].total_value?.breakdowns;
  }
}

export const eventSenderGA = (category, action, label) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};
