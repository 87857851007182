import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCookies } from 'react-cookie';
import {
  buildGraphAPIURL,
  getInsightsTotalValue,
  getInsightsValue,
} from '../tools';
import ProfileViewer from '../components/ProfileViewer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { supabase } from '../supabase/CreateClient';

dayjs.extend(relativeTime);
dayjs.locale('ko');

const MyThreads = () => {
  const mainMetaTagRenderer = () => {
    const _metaTag = (
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>My Threads Scouter for Insights</title>
        <meta name='title' content='My Threads Scouter for Insights' />
        <meta
          name='description'
          content='Dashboard for insights of my Threads'
          data-react-helmet='true'
        />
        <meta property='fb:app_id' content='7201589926609452' />
        <link rel='main-url' href={`https://threadsscouter.com/myThreads/`} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property='og:type' content='website' />
        <meta
          property='og:url'
          content='https://threadsscouter.com/myThreads/'
        />
        <meta property='og:title' content='My Threads Scouter for Insights' />
        <meta
          property='og:description'
          content='Dashboard for insights of my Threads'
        />
        <meta
          property='og:image'
          content='https://dkw7v79uvv4pm.cloudfront.net/threadsscouter/main-thumb.png'
        />
        <meta
          property='og:image:alt'
          content='Dashboard for insights of my Threads'
        />

        {/* <!-- Twitter --> */}
        <meta property='twitter:card' content='summary_large_image' />
        <meta
          property='twitter:url'
          content='https://threadsscouter.com/myThreads/'
        />
        <meta
          property='twitter:title'
          content='My Threads Scouter for Insights'
        />
        <meta
          property='twitter:description'
          content='Dashboard for insights of my Threads'
        />
        <meta
          property='twitter:image'
          content='https://dkw7v79uvv4pm.cloudfront.net/threadsscouter/main-thumb.png'
        />
        <meta
          property='twitter:image:alt'
          content='Dashboard for insights of my Threads'
        />
      </Helmet>
    );
    return _metaTag;
  };

  const navigate = useNavigate();
  const PARAMS__FIELDS = 'fields';
  const FIELD__THREADS_BIOGRAPHY = 'threads_biography';
  const FIELD__THREADS_PROFILE_PICTURE_URL = 'threads_profile_picture_url';
  const FIELD__USERNAME = 'username';
  const FIELD__VIEWS = 'views';
  const FIELD__LIKES = 'likes';
  const FIELD__REPLIES = 'replies';
  const FIELD__REPOSTS = 'reposts';
  const FIELD__QUOTES = 'quotes';
  const FIELD__FOLLOWERS_COUNT = 'followers_count';
  const FIELD__FOLLOWERS_DEMOGRAPHICS = 'follower_demographics';
  const FIELD__BREAKDOWN = 'breakdown';
  const FIELD__LIMIT = 'limit';
  const FIELD__GET_THREADS_LIMIT_NUM = 100;
  const FIELD__BREAKDOWN_METIRC = useMemo(
    () => ['country', 'city', 'age', 'gender'],
    []
  );
  const FIELD__GET_MY_THREADS =
    'timestamp,text,media_type,media_url,permalink,children';
  const FIELD__GET_A_THREADS_INSIGHTS = 'views,likes,replies,reposts,quotes';
  const PARAMS__METRIC = 'metric';
  const ERROR_LOG = 'ERROR_LOG';

  const [user, setUser] = useState();
  const [metric, setMetric] = useState();
  const [myThreadsList, setMyThreadsList] = useState([]);
  const [myThreadsListPaging, setMyThreadsListPaging] = useState();
  const [userIdCookies] = useCookies(['user_id']);
  const [isLoading, setIsLoading] = useState(false);

  const errorSender = useCallback(async (error_data) => {
    await supabase.from(ERROR_LOG).insert(error_data);
  }, []);

  useEffect(() => {
    if (!userIdCookies?.access_token) {
      alert('스레드 계정 로그인이 필요합니다.');
      return navigate('/');
    }
  }, [navigate, userIdCookies?.access_token]);

  // GET EACH THREAD'S INSIGHTS
  useEffect(() => {
    async function get_threads_insights(threads_list) {
      try {
        const includedThreads = threads_list?.filter((elem) => elem?.views);
        const NotIncludedThreads = threads_list?.filter((elem) => !elem?.views);

        const a_thread_insight_params = {
          [PARAMS__METRIC]: FIELD__GET_A_THREADS_INSIGHTS,
        };
        let threads_insights = [];
        if (NotIncludedThreads?.length > 0) {
          for (const aThread of NotIncludedThreads) {
            const aThreadInsightUrl = buildGraphAPIURL(
              `${aThread?.id}/insights`,
              a_thread_insight_params,
              userIdCookies?.access_token
            );

            await axios.get(aThreadInsightUrl).then((res_a_thread_insight) => {
              let theThread = NotIncludedThreads?.find(
                (elem) =>
                  elem?.id ===
                  res_a_thread_insight?.data?.data?.[0]?.id.split('/')[0]
              );
              if (theThread) {
                let thread_insights = {
                  views:
                    res_a_thread_insight?.data?.data?.[0]?.values[0]?.value,
                  likes:
                    res_a_thread_insight?.data?.data?.[1]?.values[0]?.value,
                  replies:
                    res_a_thread_insight?.data?.data?.[2]?.values[0]?.value,
                  reposts:
                    res_a_thread_insight?.data?.data?.[3]?.values[0]?.value,
                  quotes:
                    res_a_thread_insight?.data?.data?.[4]?.values[0]?.value,
                };
                const thread_with_insight = Object.assign(
                  theThread,
                  thread_insights
                );
                threads_insights.push(thread_with_insight);
              }
            });
          }
          Promise.all(threads_insights).then((result) => {
            setMyThreadsList([...includedThreads, ...result]);
          });
        }
      } catch (error) {
        console.log('THREAD INSIGHT ERROR', error);
      }
    }
    get_threads_insights(myThreadsList);
  }, [userIdCookies?.access_token, myThreadsList, setMyThreadsList]);

  useEffect(() => {
    let params = {
      [PARAMS__METRIC]: [
        FIELD__VIEWS,
        FIELD__LIKES,
        FIELD__REPLIES,
        FIELD__QUOTES,
        FIELD__REPOSTS,
        FIELD__FOLLOWERS_COUNT,
      ].join(','),
    };

    const threads_list_params = {
      [PARAMS__FIELDS]: FIELD__GET_MY_THREADS,
      [FIELD__LIMIT]: FIELD__GET_THREADS_LIMIT_NUM,
    };

    async function main() {
      const getUserDetailsUrl = buildGraphAPIURL(
        userIdCookies?.user_id ? userIdCookies?.user_id : 'me',
        {
          [PARAMS__FIELDS]: [
            FIELD__USERNAME,
            FIELD__THREADS_PROFILE_PICTURE_URL,
            FIELD__THREADS_BIOGRAPHY,
          ].join(','),
        },
        userIdCookies.access_token
      );

      const queryThreadsListUrl = buildGraphAPIURL(
        `${userIdCookies.user_id}/threads`,
        threads_list_params,
        userIdCookies.access_token
      );

      let userDetails = {};
      let data = [];

      // GET USER PROFILE
      try {
        await axios
          .get(getUserDetailsUrl)
          .then((res) => {
            userDetails = res.data;
            userDetails.user_profile_url = `https://www.threads.net/@${userDetails.username}`;
            setUser(userDetails);
          })
          .then(async () => {
            // GET THREADS LIST
            try {
              await axios
                .get(
                  queryThreadsListUrl
                  // user?.username === 'be_seeyong'
                  //   ? buildGraphAPIURL(
                  //       `${userIdCookies.user_id}/threads`,
                  //       {
                  //         [PARAMS__FIELDS]:
                  //           'timestamp,text,media_type,media_url,permalink',
                  //         [FIELD__LIMIT]: 5,
                  //       },
                  //       userIdCookies.access_token
                  //     )
                  //   : queryThreadsListUrl
                )
                .then(async (threads_list_res) => {
                  if (threads_list_res?.data?.data?.length > 0) {
                    setMyThreadsList(threads_list_res?.data?.data);
                    setMyThreadsListPaging(threads_list_res?.data?.paging);
                  }

                  let the_first_thread_date;
                  if (threads_list_res?.data?.data?.[0]?.timestamp) {
                    the_first_thread_date = threads_list_res?.data?.data
                      ?.map((obj) => obj?.timestamp)
                      ?.filter((times) => times >= '2024-04-13T12:00:00+0000')
                      ?.at(-1);
                  }

                  // ONLY WHEN THE FIRST THREAD DATE EXIST
                  if (the_first_thread_date) {
                    params.since = the_first_thread_date;
                  }

                  const queryThreadUrl = buildGraphAPIURL(
                    `${userIdCookies.user_id}/threads_insights`,
                    params,
                    userIdCookies.access_token
                  );
                  // GET INSIGHTS
                  try {
                    await axios.get(queryThreadUrl).then(async (res) => {
                      data = res.data;
                      const metrics = data?.data ?? [];
                      for (const index in metrics) {
                        const metric = metrics[index];
                        if (metric.name === FIELD__VIEWS) {
                          // The "views" metric returns as a value for user insights
                          getInsightsValue(metrics, index);
                        } else {
                          // All other metrics return as a total value
                          getInsightsTotalValue(metrics, index);
                        }
                      }

                      // if followers >= 100 -> Demographic info
                      if (
                        metrics?.find(
                          (met) => met.name === FIELD__FOLLOWERS_COUNT
                        )?.total_value?.value >= 100
                      ) {
                        for (let breakdown_metric of FIELD__BREAKDOWN_METIRC) {
                          const demo_params = {
                            [PARAMS__METRIC]: [
                              FIELD__FOLLOWERS_DEMOGRAPHICS,
                            ].join(','),
                            [FIELD__BREAKDOWN]: breakdown_metric,
                          };

                          const queryDemoThreadUrl = buildGraphAPIURL(
                            `${userIdCookies.user_id}/threads_insights`,
                            demo_params,
                            userIdCookies.access_token
                          );

                          await axios
                            .get(queryDemoThreadUrl)
                            .then((res_demo) => {
                              metrics.push(res_demo?.data?.data[0]);
                            });
                        }
                      }
                      Promise.all(metrics).then((result) => {
                        setMetric(result);
                      });
                    });
                  } catch (e) {
                    console.error(e);
                    errorSender({
                      error_data: e,
                      error_location: 'GET INSIGHTS',
                    });
                  }
                });
            } catch (e) {
              console.error(e);
              errorSender({
                error_data: e,
                error_location: 'GET THREADS LIST',
              });
            }
          });
      } catch (e) {
        console.error(e);
        errorSender({
          error_data: e,
          error_location: 'GET USER PROFILE',
        });
      }
    }
    main();
  }, [userIdCookies, FIELD__BREAKDOWN_METIRC, errorSender]); //user?.username

  const onClickReDoButton = async (dates) => {
    const params = {
      [PARAMS__METRIC]: [
        FIELD__VIEWS,
        FIELD__LIKES,
        FIELD__REPLIES,
        FIELD__QUOTES,
        FIELD__REPOSTS,
        FIELD__FOLLOWERS_COUNT,
      ].join(','),
    };
    params.since = dates[0];
    params.until = dates[1];

    const queryThreadUrl = buildGraphAPIURL(
      `${userIdCookies.user_id}/threads_insights`,
      params,
      userIdCookies.access_token //localStorage.getItem('access_token')
    );

    let data = [];
    try {
      await axios.get(queryThreadUrl).then(async (res) => {
        setIsLoading(true);
        data = res.data;
        const metrics = data?.data ?? [];
        for (const index in metrics) {
          const metric = metrics[index];
          if (metric.name === FIELD__VIEWS) {
            // The "views" metric returns as a value for user insights
            getInsightsValue(metrics, index);
          } else {
            // All other metrics return as a total value
            getInsightsTotalValue(metrics, index);
          }
        }
        // if followers >= 100 -> Demographic info
        if (
          metrics?.find((met) => met.name === FIELD__FOLLOWERS_COUNT)
            ?.total_value?.value >= 100
        ) {
          for (let breakdown_metric of FIELD__BREAKDOWN_METIRC) {
            const demo_params = {
              [PARAMS__METRIC]: [FIELD__FOLLOWERS_DEMOGRAPHICS].join(','),
              [FIELD__BREAKDOWN]: breakdown_metric,
            };

            const queryDemoThreadUrl = buildGraphAPIURL(
              `${userIdCookies.user_id}/threads_insights`,
              demo_params,
              userIdCookies.access_token
            );

            await axios.get(queryDemoThreadUrl).then((res_demo) => {
              metrics.push(res_demo?.data?.data[0]);
            });
          }
        }
        Promise.all(metrics).then((result) => {
          setIsLoading(false);
          setMetric(result);
        });
      });
    } catch (e) {
      setIsLoading(false);
      return e ? alert(e?.response?.data?.error?.error_user_msg) : null;
    }
  };

  const onClickLoadMoreButton = (nextLink) => async () => {
    try {
      await axios.get(nextLink).then((nextData) => {
        if (nextData?.data?.data?.length > 0) {
          setMyThreadsList([...myThreadsList, ...nextData?.data?.data]);
          setMyThreadsListPaging(nextData?.data?.paging);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onDownLoadClick = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'watchFace.clock2';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('파일 다운로드 오류:', error);
      });
  };
  const onAsyncDownLoadClick = async (url) => {
    await fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'watchFace.clock2';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('파일 다운로드 오류:', error);
      });
  };

  return (
    <div>
      {mainMetaTagRenderer()}
      <ProfileViewer
        user={user}
        metric={metric}
        myThreadsList={myThreadsList}
        myThreadsListPaging={myThreadsListPaging}
        isLoading={isLoading}
        onClickReDoButton={onClickReDoButton}
        onClickLoadMoreButton={onClickLoadMoreButton}
      />
      {user?.username === 'be_seeyong' ? (
        <>
          <button
            onClick={() =>
              onAsyncDownLoadClick(
                `https://images.ktestone.com/resultImages/insideEmotionControl/watchFace/ISTJ.clock2`
              )
            }
          >
            async
          </button>
          <button
            onClick={() =>
              onDownLoadClick(
                `https://images.ktestone.com/resultImages/insideEmotionControl/watchFace/ISTJ.clock2`
              )
            }
          >
            normal
          </button>
        </>
      ) : null}
    </div>
  );
};

export default MyThreads;
