import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { supabase } from '../../supabase/CreateClient';
import styles from './applyWaitlist.module.css';
import { Button, Modal } from 'antd';
import { eventSenderGA } from '../../tools';

const ApplyWaitlist = ({ user, metric }) => {
  const ADS_WAITLIST = 'ADS_WAITLIST';
  const [isLoading, setIsLoading] = useState(false);
  const [totalViews, setTotalViews] = useState(0);
  const [followers, setFollowers] = useState(0);
  const [email, setEmail] = useState('');
  const [noValidEmail, showNoValidEmail] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dates, setDates] = useState([]);
  // eslint-disable-next-line
  const isValidEmail = useMemo(() => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, []);

  useEffect(() => {
    if (metric?.length > 0) {
      for (let elem of metric) {
        if (elem?.name === 'views') {
          const total_views = elem?.values?.reduce(
            (n, { value }) => n + value,
            0
          );
          setTotalViews(total_views);
          setDates([elem?.values[0]?.end_time, elem?.values?.at(-1)?.end_time]);
        } else if (elem?.name === 'followers_count') {
          const total_followers = elem?.value;
          setFollowers(total_followers);
        }
      }
    }
  }, [metric]);

  const fetchUser = async (threads_url) => {
    const { data } = await supabase
      .from(ADS_WAITLIST)
      .select('*')
      .eq('threads_url', threads_url);
    return data;
  };

  const onSubmit = useCallback(
    async (user_data) => {
      if (!email || email?.length > 50 || !email?.match(isValidEmail)) {
        return showNoValidEmail(false);
      }
      setIsLoading(true);
      try {
        const data_array = await fetchUser(user_data?.threads_url);
        if (data_array?.length > 0) {
          return alert('Already Applied');
        }
        const { error } = await supabase.from(ADS_WAITLIST).insert(user_data);
        if (error) {
          return alert('Error Occured!');
        }
        showNoValidEmail(true);
        setEmail('');
        return alert('Applied Successfully!');
      } catch (error) {
        console.log(error);
        alert('Error Occured!');
      } finally {
        setIsLoading(false);
        setIsModalOpen(false);
        showNoValidEmail(true);
        setEmail('');
      }
    },
    [email, isValidEmail]
  );

  const showModal = () => {
    eventSenderGA('Popup', 'Click Ads-Popup Button', 'profile page');
    setIsModalOpen(true);
  };

  const handleOk = () => {
    eventSenderGA('Submit', 'Click Ads-list-Submit Button', 'profile page');
    onSubmit({
      threads_url: user?.user_profile_url,
      profile_views: totalViews,
      followers: followers,
      email: email,
      start_date: dates[0],
      end_date: dates[1],
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.applyWaitlist}>
      <h3 className={styles.title}>✨ 내 스레드 계정으로 광고 제안 받기 ✨</h3>
      <button
        className={styles.applyWaitlistButton}
        disabled={isLoading}
        onClick={showModal}
      >
        <p>Apply for Ads Matching</p>
      </button>
      <Modal
        onOk={handleOk}
        onCancel={handleCancel}
        open={isModalOpen}
        width={'80%'}
        style={{ maxWidth: '416px' }}
        title={
          <h4 style={{ margin: '0 auto 1rem' }}>
            💰 광고주 매칭 대기리스트 접수
          </h4>
        }
        footer={[
          <Button type='primary' key={'okay'} onClick={handleOk}>
            Okay
          </Button>,
        ]}
      >
        <h3>내 스레드 계정으로 광고 제안을 받아보세요!</h3>
        <p>
          추후 스레드에 광고하기 원하는 광고주와 매칭해 드립니다.
          <br />
          <br />
          이메일 입력 후 아래 Okay 버튼을 누르면
          <br />
          <strong style={{ color: '#1677FF', fontSize: '0.9rem' }}>
            [ 광고주 매칭 대기리스트 ]
          </strong>{' '}
          에 등록됩니다.
          <br />
          <br />
          <input
            className={styles.emailInput}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            placeholder='threads@threads.net'
            required
          />
          {!noValidEmail ? (
            <span className={styles.wrongEmailAlert}>
              * wrong type of email address
            </span>
          ) : null}
          <br />
          <br />
          광고가 매칭되면 입력하신 이메일을 통해 연락드릴게요.
          <br />
          정확한 이메일 주소를 입력해주세요.
          <br />
          <br />* 광고주 매칭 대기리스트 등록과 함께 광고주 매칭을 위한 개인
          정보 처리방침에 동의하는 것으로 간주합니다.
        </p>
      </Modal>
    </div>
  );
};

export default ApplyWaitlist;
