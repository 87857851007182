import React from 'react';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <div className={styles.footerDiv}>
      <h3>Contact</h3>
      <p>ktestone85@gmail.com</p>
      <p className='mbtiImgGen-intro-biz-info-p'>
        CookieRocket Inc. | 582-88-01697
        <br />
        Address : 409, Songpa-daero, Songpa-gu, Seoul, Republic of Korea
        <br />
        Hosting service : AWS
        <br />
        Tel : +821084542518
        <br />
      </p>
      <p>
        Disclaimer:<br></br>All content is provided for information purposes
        only
      </p>
      <p>©CookieRocket Inc. All Rights Reserved. 2024.</p>
      <p>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'https://threadsscouter.com/privacy/'}
        >
          privavy
        </a>
      </p>
      <p>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'https://threadsscouter.com/terms/'}
        >
          Terms & Conditions
        </a>
      </p>
    </div>
  );
};

export default Footer;
