import React from 'react';
import Modal from 'react-modal';
import styles from './threadsListStyle.module.css';
import AThread from '../../AThread';
import { ExportOutlined } from '@ant-design/icons';
import { eventSenderGA } from '../../../tools';

const ThreadModal = ({
  isModalOpen,
  handleCancel,
  handleOk,
  currentThread,
  myThreadsList,
}) => {
  const modalStyles = {
    overlay: {
      backgroundColor: 'black',
    },
    content: {
      backgroundColor: 'black',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      width: '85%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '1rem',
      border: '3px solid white',
    },
  };
  const onClickOriginal = () => {
    if (!currentThread?.permalink) {
      return alert('Something went WRONG!');
    }
    eventSenderGA(
      'Outlinking',
      'Click Go-to-Original Button',
      'threads list page'
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCancel}
      style={modalStyles}
      contentLabel='#AppElement'
      ariaHideApp={false}
    >
      <div className={styles.modalCloseButton} onClick={handleOk}>
        X
      </div>
      <AThread thread={currentThread ? currentThread : myThreadsList[0]} />
      <div className={styles.modalFooter}>
        <div style={{ width: '50%', display: 'inline-block' }}>
          <button
            key={'okay'}
            onClick={handleOk}
            className={styles.modalOkButton}
          >
            Okay
          </button>
        </div>
        <div style={{ width: '50%', display: 'inline-block' }}>
          <div className={styles.goToOriginal}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={currentThread?.permalink}
              onClick={onClickOriginal}
              className={styles.goToOriginalLink}
            >
              <ExportOutlined />
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ThreadModal;
