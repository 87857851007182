import React, { useState } from 'react';
import styles from '../profileViewer.module.css';
import { Button, Segmented, Tooltip } from 'antd';
import {
  LoadingOutlined,
  LockOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import GridStyleList from './ThreadsListStyle/GridStyleList';
import ListStyleList from './ThreadsListStyle/ListStyleList';

const MyThreadsList = ({
  myThreadsList,
  myThreadsListPaging,
  onClickLoadMoreButton,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentThread, setCurrentThread] = useState();
  const [listStyle, setListStyle] = useState('grid');

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = (thread) => () => {
    setCurrentThread(thread);
    setIsModalOpen(true);
  };

  return (
    <div className={styles.myThreadsListDiv}>
      <Tooltip
        title='We NEVER store your ANY data.'
        color='gold'
        placement='top'
      >
        <h3 className={styles.demoTitle}>
          My Threads List <LockOutlined />
        </h3>
      </Tooltip>
      <Segmented
        className={styles.listStyleSegmented}
        options={[
          {
            label: (
              <div className={styles.eachSegment}>
                <TableOutlined />
              </div>
            ),
            value: 'grid',
          },
          {
            label: (
              <div className={styles.eachSegment}>
                <UnorderedListOutlined />
              </div>
            ),
            value: 'list',
          },
        ]}
        onChange={(value) => {
          setListStyle(value);
        }}
      />
      {myThreadsList ? (
        <div className={styles.myThreadsListInnerDiv}>
          {listStyle === 'grid' ? (
            <GridStyleList
              myThreadsList={myThreadsList}
              handleOk={handleOk}
              handleCancel={handleCancel}
              isModalOpen={isModalOpen}
              currentThread={currentThread}
              showModal={showModal}
            />
          ) : (
            <ListStyleList
              myThreadsList={myThreadsList}
              handleOk={handleOk}
              handleCancel={handleCancel}
              isModalOpen={isModalOpen}
              currentThread={currentThread}
              showModal={showModal}
            />
          )}
          {myThreadsListPaging?.next ? (
            <Button
              type='dashed'
              className={styles.myThreadsListLoadMoreButton}
              onClick={onClickLoadMoreButton(myThreadsListPaging?.next)}
            >
              load more..
            </Button>
          ) : null}
        </div>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  );
};

export default MyThreadsList;
