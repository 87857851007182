import React, { useCallback } from 'react';
import styles from '../profileViewer.module.css';
import { Button, ConfigProvider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { eventSenderGA } from '../../tools';
import { useCookies } from 'react-cookie';

const BasicProfile = ({ user }) => {
  // eslint-disable-next-line
  const [userIdCookies, setUserIdCookies, removeUserIdCookies] = useCookies([
    'user_id',
  ]);
  const navigate = useNavigate();
  const onClickLogoutButton = useCallback(() => {
    eventSenderGA('LogOut', 'Click LogOut Button', 'profile page');
    if (userIdCookies?.access_token) {
      try {
        removeUserIdCookies('access_token');
        removeUserIdCookies('user_id');
        alert('Logout Successfully!');
        navigate('/');
      } catch (error) {
        console.error(error);
        alert('Error occured, Go to Home!');
        navigate('/');
      }
    } else {
      alert('No User info, Go to Home!');
      navigate('/');
    }
  }, [navigate, userIdCookies, removeUserIdCookies]);

  return (
    <div className={styles.userProfileDiv}>
      {user ? (
        <>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={user?.user_profile_url}
          >
            <img
              src={user?.threads_profile_picture_url}
              alt={user?.username}
              className={styles.userProfilePicture}
            />
          </a>
          <a
            style={{ color: 'white' }}
            target='_blank'
            rel='noopener noreferrer'
            href={user?.user_profile_url}
          >
            <h2>{user?.username}</h2>
          </a>
          <h5>{user?.threads_biography}</h5>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainer: 'black',
                },
              },
            }}
          >
            <Button
              onClick={onClickLogoutButton}
              type='dashed'
              danger
              style={{ marginTop: '1.5rem' }}
            >
              Logout
            </Button>
          </ConfigProvider>
        </>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  );
};

export default BasicProfile;
