import { Button, Col, ConfigProvider, DatePicker, Row, Tooltip } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import styles from '../profileViewer.module.css';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

dayjs.extend(relativeTime);
dayjs.extend(utc);

const BasicInsights = ({
  normalMetric,
  setNorMalMetric,
  onClickReDoButton,
  isLoading,
}) => {
  const [dates, setDates] = useState([]);
  const [newDates, setNewDates] = useState([]);
  // eslint-disable-next-line
  const onSelect = (_, dateString) => {
    setNewDates([
      dayjs(dateString?.[0]).add(1, 'day'),
      dayjs(dateString?.[1]).add(1, 'day'),
    ]); //.utc()
  };

  useEffect(() => {
    const views_metric = normalMetric?.find((item) => item.name === 'views');
    setDates([
      views_metric?.values[0].end_time,
      views_metric?.values?.at(-1).end_time,
    ]);
  }, [normalMetric, setDates]);

  return (
    <div className={styles.userInsightsDiv}>
      <h3 className={styles.demoTitle}>Basic Insights</h3>
      {normalMetric ? (
        <>
          <div className={styles.datesRenderDiv}>
            <p>{dayjs(dates[0]).format('YYYY-MM-DD')}</p>~
            <p>{dayjs(dates[1]).format('YYYY-MM-DD')}</p>
          </div>
          <div className={styles.dateRangeDiv}>
            <RangePicker
              // minDate={dayjs(firstDate)}
              maxDate={dayjs()}
              onChange={onSelect}
              // value={newDates}
              allowClear={false}
              inputReadOnly
              className={styles.dateRangePicker}
              popupStyle={{ flexDirection: 'column' }}
            />
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorBgContainerDisabled: 'grey',
                    colorBorder: 'none',
                  },
                },
              }}
            >
              <Button
                className={styles.reDoButton}
                onClick={() => {
                  setNorMalMetric([]);
                  setNewDates([]);
                  onClickReDoButton(newDates);
                }}
                disabled={newDates?.length < 2}
              >
                ✨ Re-Analyze
              </Button>
              <p></p>
            </ConfigProvider>
          </div>
          {isLoading ? (
            <LoadingOutlined />
          ) : (
            <Row className={styles.userInsightsRow}>
              {normalMetric?.map((metric_elem) => (
                <Fragment key={metric_elem?.name}>
                  <Col className={styles.userInsightsCol} span={8}>
                    <h3>
                      {metric_elem?.name === 'views' ? (
                        <Tooltip
                          title='This metric is an "In-development" metric by Meta Platforms, Inc.'
                          color='gold'
                          placement='topRight'
                        >
                          <div style={{ cursor: 'pointer' }}>
                            {metric_elem?.title?.split('_')?.[0]}{' '}
                            <QuestionCircleOutlined />
                          </div>
                        </Tooltip>
                      ) : (
                        metric_elem?.title?.split('_')?.[0]
                      )}
                    </h3>
                    <h4 className={styles.insightDesc}>
                      {metric_elem?.description?.replace('.', '')}
                    </h4>
                    <h2 className={styles.insightValueNumber}>
                      {metric_elem?.name === 'views'
                        ? metric_elem?.values
                            ?.reduce((n, { value }) => n + value, 0)
                            ?.toLocaleString()
                        : metric_elem?.value?.toLocaleString()}
                    </h2>
                  </Col>
                </Fragment>
              ))}
            </Row>
          )}
        </>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  );
};

export default BasicInsights;
